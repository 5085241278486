var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-button',{staticClass:"settings-button",attrs:{"type":"text-base","name":"Настройки","icon":"settings","offset-icon":"4px","padding-left":"6px","padding-right":"10px","height":"32px"},on:{"click":function($event){return _vm.$modal.open({
      name: _vm.MODAL_TITLE.SETTINGS_TABLE_CELLS,
      props: {
        tableCellsConfig: _vm.tableCellsConfig,
        saveTableCellsConfigFunc: _vm.saveTableCellsConfigFunc,
        page: _vm.page
      }
    })}}})
}
var staticRenderFns = []

export { render, staticRenderFns }