var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"dashboard-block _mb-32"},[_c('v-container',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"total"},[_vm._v("Всего: "),_c('span',[_vm._v(_vm._s(_vm._f("formatCount")(_vm.tableData.length)))])])]},proxy:true},{key:"head-middle",fn:function(){return [_c('div',{staticClass:"buttons"},[_c('v-filters-reset-button',{attrs:{"metrics":_vm.metricFilters.map(metric => metric.filterName)}}),_c('v-table-settings-button',{attrs:{"table-cells-config":_vm.tableCellsConfig,"save-table-cells-config-func":_vm.saveTableCellsConfig,"page":"abcAnalysis"}})],1)]},proxy:true}])},'v-container',{
        load: _vm.preloader,
        hasData: !!_vm.tableData.length
      },false),[_c('v-table',_vm._b({},'v-table',{
          data: _vm.tableDataSorted,
          dateFilters: _vm.dateFilters,
          tableCellsConfig: _vm.tableCellsConfig,
          metricsVisible: _vm.metricsVisible
        },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }