<template>
  <div
      ref="container"
      class="dashboard-block _mb-32"
  >
    <v-container
        v-bind="{
          load: preloader,
          hasData: !!tableData.length
        }"
    >
      <template #title>
        <p class="total">Всего: <span>{{ tableData.length | formatCount }}</span></p>
      </template>

      <template #head-middle>
        <div class="buttons">
          <v-filters-reset-button :metrics="metricFilters.map(metric => metric.filterName)" />
          <v-table-settings-button
              :table-cells-config="tableCellsConfig"
              :save-table-cells-config-func="saveTableCellsConfig"
              page="abcAnalysis"
          />
        </div>
      </template>

      <v-table
          v-bind="{
            data: tableDataSorted,
            dateFilters,
            tableCellsConfig,
            metricsVisible
          }"
      />
    </v-container>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import MODAL_TITLE from '@/modals/modalTitle'
import {NOTIFY_STATUS} from '@/const'

import container from '@/services/components/container/'
import tableSettingsButton from '@/services/components/tableSettingsButton'
import table from './table'
import filtersResetButton from '@/services/components/filtersResetButton'

import updateUrl from '@/mixins/updateUrl'

const TRAFFIC_FILTER_TYPES = ['source', 'medium']

export default {
  name: 'tableContainer',

  mixins: [updateUrl],

  provide () {
    return {
      tableCellsConfigKey: this.tableCellsConfigKey
    }
  },

  components: {
    'v-container': container,
    'v-table-settings-button': tableSettingsButton,
    'v-table': table,
    'v-filters-reset-button': filtersResetButton
  },

  props: {
    urlFilters: {
      type: Object,
      default: () => ({})
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    regionSelected: {
      type: Array
    },

    metrics: {
      type: Array,
      default: () => ([])
    },

    metricFilters: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      preloader: true,
      MODAL_TITLE,
      tableCellsConfigKey: 'abcAnalysisTableCellsConfig'
    }
  },

  computed: {
    ...mapState({
      tableCellsConfig: state => state.tableSettings.abcAnalysisTableCellsConfig
    }),

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = this.$route.params.activeProject
      return id ? +id : undefined
    },

    tableData() {
      return this.$store.getters['dashboard/abcAnalysis/data']
    },

    /* Список метрик для отображения */
    metricsVisible () {
      return this.metrics.filter(metric => metric.visible && metric.parent_id !== '0')
    },

    tableDataFiltered() {
      let data = [...this.tableData]

      if (data.length) {
        this.metricFilters.forEach(metric => {
          if (this.urlFilters[`${metric.filterName}_category`]) {
            data = data.filter(item => this.urlFilters[`${metric.filterName}_category`].includes(item[metric.id].category))
          }
        })
      }

      return data
    },

    tableDataSorted() {
      return [...this.tableDataFiltered].sort((a, b) =>  b.count_all && a.count_all ? b.count_all.value - a.count_all.value : 0)
    }
  },

  watch: {
    async $route (to, from) {
      const qt = to.query
      const qf = from.query

      for (let metric of this.metricFilters) {
        if (qt[`${metric.filterName}_category`] !== qf[`${metric.filterName}_category`]) {
          return
        }
      }

      this.setCancelRequests(true)
      await this.init()
    }
  },

  async created () {
    this.setCancelRequests(false)
    this.setTableCellsConfig()

    await this.init()
  },

  destroyed () {
    this.setCancelRequests(true)
  },

  methods: {
    ...mapActions({
      getCatalog: 'catalog/getCatalog'
    }),

    ...mapMutations({
      saveTableCellsConfig: 'tableSettings/saveTableCellsConfig',
      setCancelRequests: 'setCancelRequests'
    }),

    setTableCellsConfig () {
      let tableCellsConfig = localStorage.getItem(this.tableCellsConfigKey)
      let newConfig = {}

      if (tableCellsConfig) {
        tableCellsConfig = JSON.parse(tableCellsConfig)
        this.tableCellsConfig.forEach(section => {
          section.items.forEach(metric => {
            newConfig[metric.id] = metric.disabled || !!tableCellsConfig[metric.id]
          })
          newConfig[section.id] = section.items.some(metric => metric.disabled || !!tableCellsConfig[metric.id])
        })
        this.saveTableCellsConfig({newConfig, key: this.tableCellsConfigKey})
      } else {
        this.tableCellsConfig.forEach(section => {
          newConfig[section.id] = section.items.some(metric => metric.visible)
          section.items.forEach(metric => {
            newConfig[metric.id] = metric.visible
          })
        })
      }
      this.saveTableCellsConfig({newConfig, key: this.tableCellsConfigKey})
    },

    /* Инициализация данных */
    async init (filters) {
      this.preloader = true
      this.$emit('load-catalog', this.preloader)
      try {
        await this.requestData(0, filters)
      } catch ({message}) {
        this.$notify({ type: NOTIFY_STATUS.ERROR, title: message })
      } finally {
        this.preloader = false
        this.$emit('load-catalog', this.preloader)
      }
    },

    /* Загрузка данных */
    async requestData (summary, filters) {
      if (this.activeProjectId) {
        let query = {
          project_id: this.activeProjectId,
          catalog_id: '0',
          skip_regionality: 1,
          catalog_ids: this.urlFilters.catalog_ids,
          v2: 1,
          catalog_filter_mode: 'as_is',
          is_mobile: this.urlFilters.is_mobile === 0 ? '0' : this.urlFilters.is_mobile,
          search_engine: this.urlFilters.search_engine,
          date_from: this.dateFilters.from,
          date_to: this.dateFilters.to,
          limit: 100,
          traffic_source: this.urlFilters.traffic_source,
          ...filters
        }

        if (this.regionSelected) {
          query.region_id = this.regionSelected.map(region => region.yandex_region_id).filter(id => id)
          query.google_region_id = this.regionSelected.map(region => region.google_region_id)
          query.need_geo = this.regionSelected && this.regionSelected.length ? 1 : undefined
        }

        for (let type of TRAFFIC_FILTER_TYPES) {
          query[type] = this.urlFilters[type]
          query[`traffic_conditions[${type}]`] = this.urlFilters[`traffic_conditions[${type}]`]
        }

        await this.getCatalog({
          query,
          level: '0'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.total {
  span {
    font-weight: 500;
  }
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
</style>
