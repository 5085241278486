var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-page-title',_vm._b({staticClass:"_border-bottom _mb-0"},'v-page-title',_vm.title,false)),(_vm.viewPage)?[_c('v-filters',_vm._b({on:{"update-url":_vm.updateUrl,"finish-filters-loading":_vm.finishFiltersLoading}},'v-filters',{
          urlFilters: _vm.urlFilters,
          dateFilters: _vm.dateFilters,
          searchEngines: _vm.searchEngines,
          regions: _vm.regions,
          devices: _vm.devices
        },false)),(!_vm.preloader.region)?_c('div',{staticClass:"dashboard-wrapper _border-none _mt-32"},[(!_vm.preloader.filters)?_c('v-table-container',_vm._b({},'v-table-container',{
            urlFilters: _vm.urlFilters,
            dateFilters: _vm.dateFilters,
            regionSelected: _vm.regionSelected,
            metrics: _vm.metrics,
            metricFilters: _vm.metricFilters
          },false)):_vm._e()],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }