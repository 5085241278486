<template>
  <vue-table
      v-if="data.length"
      small-padding
      head-background
      fixed-bar
      fixed-head
      :offset-top="56"
      :hoverable-rows="false"
  >
    <template #head>
      <vue-line>
        <vue-item
            rowspan="2"
            valign="bottom"
        >
          Сегменты
        </vue-item>
        <vue-item
            rowspan="2"
            valign="bottom"
        >
          Уровень вложенности
        </vue-item>
        <template v-for="cell of tableCellsConfig">
          <vue-item
              v-if="cell.items.filter(item => item.visible).length"
              :key="cell.id"
              :colspan="getColspan(cell)"
          >
            {{ cell.name }}
          </vue-item>
        </template>
      </vue-line>
      <vue-line>
        <v-table-head-cell-container
            v-for="(metric, i) of metricsVisible"
            :key="`${metric.id}-${i}`"
            show-buttons-on-hover
            :filter-options="{
              metric: metric,
              types: ['abc']
            }"
            filter
        >
            <span class="table-head-metric-name">
              {{ metric.name }}
            </span>
        </v-table-head-cell-container>
      </vue-line>
    </template>
    <template #body>
      <v-table-row
          v-for="item of data"
          :key="`${JSON.stringify(item)}`"
          :item="item"
          :metrics="metricsVisible"
          :level="getLevel(item.id)"
      />
    </template>
  </vue-table>
  <v-no-data v-else />
</template>

<script>
import {mapState} from 'vuex'
import {findOptions} from '@/function'

import tableRow from './tableRow'
import noData from '@/services/errors/noData.vue'
import tableHeadCellContainer from '@/components/tableHeadCell/container/tableHeadCellContainer'

import updateUrl from '@/mixins/updateUrl'

export default {
  mixins: [updateUrl],

  components: {
    'v-table-row': tableRow,
    'v-no-data': noData,
    'v-table-head-cell-container': tableHeadCellContainer
  },

  props: {
    data: {
      type: Array,
      default: () => ([])
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    tableCellsConfig: {
      type: Array,
      default: () => ([])
    },

    metricsVisible: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      preloaderLeft: 0
    }
  },

  computed: {
    ...mapState({
      preloaderTable: state => state.catalog.preloaderTable,
      segmentsCatalogDF: state => state.segments.catalog.dataDF
    }),

    tableCellsCount () {
      let count = this.metricsVisible.length + 1

      if (this.dateFilters.comparison) count *= 3

      return count + 1
    }
  },

  mounted () {
    try {
      document.querySelector('.__panel').addEventListener('scroll', this.setPreloaderLeft)
    } catch (_) {
    }
  },

  methods: {
    getColspan (cell) {
      const count = cell.items.reduce((prev, curr) => {
        if (curr.visible) {
          prev++
        }
        return prev
      }, 0)

      return count
    },

    setPreloaderLeft () {
      try {
        this.preloaderLeft = document.querySelector('.__panel').scrollLeft
      } catch (_) {
      }
    },

    getLevel (id) {
      const item = findOptions(String(id), this.segmentsCatalogDF.items)

      return item && item.level || 0
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  &-head-metric-name {
    white-space: nowrap;

    &._clickable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-line._active {
    .table-item {
      background: #F9FCFF !important;
    }
  }

  &-item {
    &._nowrap {
      white-space: nowrap;
    }

    &._active {
      color: #0E32F5;
    }
  }

  &-fixed {
    & /deep/ .table {
      table-layout: fixed !important;
    }
  }
}
</style>
