<template>
  <vue-item
      align="right"
      :colspan="colspan"
      class="table-segment-metric"
      :style="comparison && (diffPrc || diffPrc === 0) ? {height: '57px'} : undefined"
  >
    <vue-element
        v-if="comparison"
        type="label"
        label-note="X"
        :data="comparisonData"
    />
    <template v-else>
      <span v-if="mainFlag && isListing">-</span>
      <div
          v-else-if="diff"
          class="metric-diff"
      >
        <span
            v-if="diffPrc || diffPrc === 0"
            class="metric-diff__value"
        >
          {{ valueFormatted | formatCount }}
        </span>
        <span
            :class="[
                'metric-diff__prc',
                {
                  '_pos': invertDiffs ? valueDiff < 0 : valueDiff > 0,
                  '_neg': invertDiffs ? valueDiff > 0 : valueDiff < 0
                }
              ]"
        >
          <span v-if="valueDiff > 0">+</span>{{ valueDiff | formatCount }}<span v-if="valueDiff !== 0 && valueDiff !== '-'">%</span>
        </span>
      </div>
      <div v-else class="metric">
        <span class="metric-value">{{ valueFormatted | formatCount }}</span>
        <span v-if="additional && value !== '-'">{{ additional }}</span>
      </div>
    </template>
  </vue-item>
</template>

<script>
import {roundNumber, hmsToSeconds} from '@/function'

export default {
  props: {
    value: {
      type: [Number, String],
      default: '-'
    },

    diff: {
      type: Boolean,
      default: false
    },

    additional: {
      type: String,
      default: ''
    },

    colspan: {
      type: [Number, String],
      default: undefined
    },

    isListing: {
      type: Boolean,
      default: false
    },

    diffPrc: {
      type: Number,
      default: undefined
    },

    comparison: {
      type: Boolean,
      default: false
    },

    valueComparison: {
      type: [Number, String],
      default: '-'
    },

    invertDiffs: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    valueFormatted () {
      try {
        if (this.additional === '%') {
          return this.value.toFixed(2)
        } else {
          return this.value
        }
      } catch (_) {
        return 0
      }
    },

    valueDiff () {
      let diff = this.diffPrc || this.diffPrc === 0 ? this.diffPrc : this.value
      try {
        return diff.toFixed(2)
      } catch (_) {
        return 0
      }
    },

    mainFlag () {
      let flag = this.$route.query.main
      return +flag === 1 ? flag : undefined
    },

    comparisonData () {
      try {
        const value = typeof this.value === 'string' ? hmsToSeconds(this.value) : this.value

        return this.valueComparison === 0
            ? value === 0
                ? 0
                : 1
            : roundNumber((value - this.valueComparison) / this.valueComparison, 2) || 0
      } catch (_) {
        return 0
      }
    }
  }
}
</script>

<style scoped lang="scss">
.metric {
  &-diff {
    display: flex;
    flex-direction: column;

    &__prc {
      padding: 1px 3px;
      border-radius: 6px;
      background: #9D99AA;
      color: #fff;
      font-weight: 500;
      white-space: nowrap;

      &._pos {
        background: #00C853;
      }

      &._neg {
        background: #F44336;
      }
    }
  }
}

.table {
  &-segment {
    &-metric {
      width: calc((100% - 680px) / 7);
      min-width: calc((100% - 680px) / 7);
      max-width: calc((100% - 680px) / 7);
    }
  }
}
</style>
