<template>
  <div class="name-container">
    <div :class="['name-container__top', label.class]">
      <span class="name-title">
        <span v-if="prefix" class="name-title__prefix">{{ prefix }}: </span>{{ title | maxLength(maxNameLength, sliceName) }}
      </span>
      <ul
          v-if="type === catalogItemTypes.CATEGORY && isListing"
          class="name-count"
      >
        <li
            v-for="(item, i) of entityCount"
            :key="i"
            :class="item.type"
            v-tippy="{
              content: item.name,
              size: 'small'
            }"
        >
          {{ item.count | formatCount }}
        </li>
      </ul>
      <span :class="['name-label', label.class]">{{ label.text }}</span>
    </div>
    <router-link
        v-if="!isListing && url"
        class="name-link"
        :to="{ name: 'redirect', query: { q: url } }"
        target="_blank"
    >
      {{ url | maxLength(maxNameLength, sliceName) }}
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    url: {
      type: String,
      default: ''
    },

    sliceName: {
      type: Boolean,
      default: false
    },

    level: {
      type: Number,
      default: 0
    },

    type: {
      type: Number,
      default: 0
    },

    isListing: {
      type: Boolean,
      default: false
    },

    listingPage: {
      type: Boolean,
      default: false
    },

    entityCount: {
      type: Array,
      default: () => ([])
    },

    prefix: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      maxNameLength: 50
    }
  },

  computed: {
    ...mapState({
      catalogItemTypes: state => state.catalog.catalogItemTypes
    }),

    label () {
      if (this.type === this.catalogItemTypes.CATEGORY && this.isListing) {
        return {
          text: 'Категории',
          class: '_category'
        }
      } else if (this.type === this.catalogItemTypes.CATEGORY && !this.isListing) {
        return {
          text: 'Листинг',
          class: '_listing'
        }
      } else if (this.type === this.catalogItemTypes.CATEGORY && this.listingPage && this.mainFlag) {
        return {
          text: 'Листинг',
          class: '_listing'
        }
      } else if (this.type === this.catalogItemTypes.CARD) {
        return {
          text: 'Карточка',
          class: '_card'
        }
      } else if (this.type === this.catalogItemTypes.TAG_LISTING) {
          return {
            text: 'Тэговый листинг',
            class: '_tag'
          }
      } else {
        return {
          text: '',
          class: ''
        }
      }
    },

    mainFlag () {
      let flag = this.$route.query.main
      return +flag === 1 ? flag : undefined
    }
  }
}
</script>

<style scoped lang="scss">
%entity {
  position: relative;
  padding: 0 10px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
    width: 1px;
    height: 12px;
    background: #BDBDBD;
  }
}

.name-container {
  display: flex;
  flex-direction:  column;
  margin-left: 5px;
  width: 100%;

  &._ml-0 {
    margin-left: 0;
  }

  &__label {
    font-weight: 500;
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    max-width: 500px;
  }

  &__list {
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    white-space: nowrap;

    display: flex;
    align-items: center;

    &._circumcision {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    li {
      display: inline;

      &:last-child {
        margin-right: 3px;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.name {
  &-link {
    color: #9D99AA;

    &:hover {
      color: #4158D9;
    }
  }

  &-label {
    margin-left: auto;
    padding: 0 1px;
    border-radius: 6px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 12px;
    white-space: nowrap;

    &._category {
      background: #FFF5E0;
      color: #FF8F00;
    }

    &._listing {
      background: #F3EBFF;
      color: #7E57C2;
    }

    &._card {
      background: #E2FFEE;
      color: #00C853;
    }

    &._tag {
      background: #E2FFF5;
      color: #2F80ED;
    }
  }

  &-count {
    display: flex;
    align-items: center;
    height: 20px;
    margin: 0 8px;
    padding: 2px 10px 2px 0;
    border-radius: 100px;
    background: rgba(69, 81, 101, .07);
    font-size: 12px;

    li {
      &.elements {
        padding: 0 10px;
        border-radius: 100px;
        background: rgba(69, 81, 101, .07);
        color: #7D7D7D;
        font-weight: 500;
      }

      &.listings {
        @extend %entity;
        color: #FFAB00;
      }

      &.tag_listings {
        @extend %entity;
        color: #2F80ED;
      }

      &.offers {
        padding-left: 10px;
        color: #00C853;
      }
    }
  }

  &-title__prefix {
    color: #9D99AA;
  }
}
</style>
