var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-item',{staticClass:"table-segment-metric",style:(_vm.comparison && (_vm.diffPrc || _vm.diffPrc === 0) ? {height: '57px'} : undefined),attrs:{"align":"right","colspan":_vm.colspan}},[(_vm.comparison)?_c('vue-element',{attrs:{"type":"label","label-note":"X","data":_vm.comparisonData}}):[(_vm.mainFlag && _vm.isListing)?_c('span',[_vm._v("-")]):(_vm.diff)?_c('div',{staticClass:"metric-diff"},[(_vm.diffPrc || _vm.diffPrc === 0)?_c('span',{staticClass:"metric-diff__value"},[_vm._v(" "+_vm._s(_vm._f("formatCount")(_vm.valueFormatted))+" ")]):_vm._e(),_c('span',{class:[
              'metric-diff__prc',
              {
                '_pos': _vm.invertDiffs ? _vm.valueDiff < 0 : _vm.valueDiff > 0,
                '_neg': _vm.invertDiffs ? _vm.valueDiff > 0 : _vm.valueDiff < 0
              }
            ]},[(_vm.valueDiff > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("formatCount")(_vm.valueDiff))),(_vm.valueDiff !== 0 && _vm.valueDiff !== '-')?_c('span',[_vm._v("%")]):_vm._e()])]):_c('div',{staticClass:"metric"},[_c('span',{staticClass:"metric-value"},[_vm._v(_vm._s(_vm._f("formatCount")(_vm.valueFormatted)))]),(_vm.additional && _vm.value !== '-')?_c('span',[_vm._v(_vm._s(_vm.additional))]):_vm._e()])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }