<template>
  <v-button
      class="settings-button"
      type="text-base"
      name="Настройки"
      icon="settings"
      offset-icon="4px"
      padding-left="6px"
      padding-right="10px"
      height="32px"
      @click="$modal.open({
        name: MODAL_TITLE.SETTINGS_TABLE_CELLS,
        props: {
          tableCellsConfig,
          saveTableCellsConfigFunc,
          page
        }
      })"
  />
</template>

<script>
import MODAL_TITLE from '@/modals/modalTitle'

export default {
  name: 'tableSettingsButton',

  props: {
    tableCellsConfig: {
      type: Array,
      default: () => ([])
    },

    saveTableCellsConfigFunc: {
      type: Function,
      default: () => false
    },

    page: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      MODAL_TITLE
    }
  }
}
</script>

<style scoped lang="scss">
.settings-button {
  & /deep/ .button__icon {
    width: 20px;
    height: 20px;
  }
}
</style>
