<template>
  <div>
    <v-page-title
        class="_border-bottom _mb-0"
        v-bind="title"
    />

    <template v-if="viewPage">
      <v-filters
          v-bind="{
            urlFilters,
            dateFilters,
            searchEngines,
            regions,
            devices
          }"
          @update-url="updateUrl"
          @finish-filters-loading="finishFiltersLoading"
      />

      <div
          v-if="!preloader.region"
          class="dashboard-wrapper _border-none _mt-32"
      >

        <v-table-container
            v-if="!preloader.filters"
            v-bind="{
              urlFilters,
              dateFilters,
              regionSelected,
              metrics,
              metricFilters
            }"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {findOptions} from '@/function'

import updateUrl from '@/mixins/updateUrl'
import regionFilter from '@/mixins/regionFilter'

import filters from './components/filters'
import tableContainer from './components/tableContainer/'
import error from '@/services/errors/block'
import container from '@/services/components/container/'

import {SEARCH_SYSTEM, NOTIFY_STATUS, DATE_FORMAT} from '@/const/'

const TRAFFIC_FILTER_TYPES = ['source', 'medium']

export default {
  name: 'abcAnalysis',

  metaInfo () {
    return {
      title: `${this.title.primary} / ${this.title.secondary} / ${this.activeProjectData.label} (${this.activeProjectData.id})`
    }
  },

  mixins: [
    updateUrl,
    regionFilter
  ],

  components: {
    'v-filters': filters,
    'v-table-container': tableContainer,
    'v-error': error,
    'v-container': container
  },

  data () {
    return {
      title: {
        primary: 'Data analytics',
        secondary: 'ABC-analysis'
      },

      preloader: {
        filters: true,
        region: true
      },

      searchEngines: [
        {id: SEARCH_SYSTEM.GOOGLE, name: 'Google'},
        {id: SEARCH_SYSTEM.YANDEX, name: 'Яндекс'}
      ],

      dateDefault: {
        from: +this.$moment.utc().startOf('month').subtract(1, 'months').format('x'),
        to: +this.$moment.utc().endOf('month').subtract(1, 'months').format('x')
      }
    }
  },

  computed: {
    ...mapState({
      activeProjectData: state => state.active_project_data,
      segmentsCatalogDF: state => state.segments.catalog.dataDF,
      catalog: state => state.catalog.catalog,
      trafficConditions: state => state.trafficConditions,
      filtersTraffic: state => state.filtersTraffic,
      trafficSources: state => state.trafficSources
    }),

    ...mapGetters(['filtersDevices']),
    ...mapGetters({
      tableCellsConfig: 'tableSettings/getTableCellsConfigAbcAnalysis'
    }),

    viewPage () {
      return this.activeProjectData && Object.keys(this.activeProjectData).length !== 0
    },

    /* Фильтры в URL */
    urlFilters () {
      let queryParams = {...this.$route.query}
      let filters = {
        date: {
          from: undefined,
          to: undefined
        }
      }

      /* Инициализация URL фильтров */
      const initFilters = (query, options, required = false, defaultOption, cycle, optionKey = 'id', number = false, string = false) => {
        try {
          if (cycle) {
            if (query) {
              /* Проверка наличия опций в фильтрах */
              if (query && Array.isArray(query)) {
                return query.map(f => number ? +f : f).filter(f => f !== null && f !== undefined && ((!string && !isNaN(f)) || string))
              } else if (typeof query === 'string' && number && !isNaN(+query)) {
                return [+query]
              } else if ((typeof query === 'number' && !isNaN(+query)) || typeof query === 'string' && !number) {
                return [query]
              } else {
                return defaultOption || []
              }
            } else {
              return defaultOption || []
            }
          } else {
            if (typeof query === 'object' && options) {
              for (let item of options) {
                if (query.source && query.source === item.source && query.medium && query.medium === item.medium) {
                  return item.id
                }
              }
            } else {
              if ((query || query === 0) && findOptions(query, options, optionKey)) {
                return query
              } else if (required) {
                return defaultOption || options[0].id
              } else {
                return null
              }
            }
          }
        } catch (_) {
          return null
        }
      }

      filters.catalog_ids = initFilters(queryParams.catalog_ids, this.segmentsCatalogDF.items, false, [], true).filter(id => !isNaN(+id))

      filters.is_mobile = initFilters(+queryParams.is_mobile, this.devices)

      filters.search_engine = initFilters(queryParams.search_engine, this.searchEngines)

      filters.region_id = initFilters(queryParams.region_id, this.regions, false, undefined, true, 'google_region_id', true)

      for (let type of TRAFFIC_FILTER_TYPES) {
        filters[type] = initFilters(queryParams[type], this.filtersTraffic[type].items, false, [], true, undefined, false, true)
        filters[`traffic_conditions[${type}]`] = initFilters(queryParams[`traffic_conditions[${type}]`], this.trafficConditions)
      }

      const abcCategories = [
        {id: 'a', name: 'A'},
        {id: 'b', name: 'B'},
        {id: 'c', name: 'C'}
      ]
      this.metricFilters.forEach(item => {
        if (queryParams[`${item.filterName}_category`]) {
          let queryValue = queryParams[`${item.filterName}_category`].split('')
          let filterValue = []

          queryValue.forEach(item => {
            if (findOptions(item, abcCategories) && !filterValue.includes(item)) {
             filterValue.push(item)
            }
          })

          filters[`${item.filterName}_category`] = filterValue
        }
      })

      const getUrlDate = date => {
        const dateFrom = +this.$moment.utc(date, DATE_FORMAT.BASE_FORMAT).startOf('month').format('x')
        const dateTo = +this.$moment.utc(date, DATE_FORMAT.BASE_FORMAT).endOf('month').format('x')

        filters.date = {
          from: dateFrom && dateFrom <= this.dateDefault.from ? dateFrom : this.dateDefault.from,
          to: dateTo && dateTo <= this.dateDefault.to ? dateTo : this.dateDefault.to,
        }
      }

      if (queryParams.date_from) {
        getUrlDate(queryParams.date_from)
      } else if (queryParams.date_to) {
        getUrlDate(queryParams.date_to)
      } else {
        filters.date = this.dateDefault
      }

      filters.traffic_source = initFilters(queryParams.traffic_source, this.trafficSources, true, 'ga4')

      return filters
    },

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = this.$route.params.activeProject
      return id ? +id : undefined
    },

    dateFilters () {
      let dateFilters = {
        from: undefined,
        to: undefined
      }

      const urlFiltersDate = {...this.urlFilters.date}

      try {
        if (urlFiltersDate && urlFiltersDate.from) {
          dateFilters.from = this.$moment.utc(urlFiltersDate.from).format(DATE_FORMAT.BASE_FORMAT)
        }

        if (urlFiltersDate && urlFiltersDate.to) {
          dateFilters.to = this.$moment.utc(urlFiltersDate.to).format(DATE_FORMAT.BASE_FORMAT)
        }
      } catch (_) {
      }

      return dateFilters
    },

    devices() {
      return this.filtersDevices
    },

    regionSelected() {
      return this.getRegionSelected(this.urlFilters.region_id)
    },

    /* Список всех метрик */
    metrics () {
      let metrics = []

      this.tableCellsConfig.forEach(section => {
        metrics.push({...section, parent_id: '0'})
        section.items.forEach(metric => {
          metrics.push({...metric, parent_id: section.id})
        })
      })

      return metrics
    },

    metricFilters() {
      return this.metrics.filter(metric => metric.parent_id !== '0')
    }
  },

  async created () {
    this.$store.commit('catalog/saveCatalog', {
      hasData: false,
      catalogId: '0'
    })

    try {
      await this.getFilters({
        query: {
          project_id: this.activeProjectId,
          skip_regionality: 1
        }
      })
    } catch ({message}) {
      this.$notify({title: message, type: NOTIFY_STATUS.ERROR})
    }

    await this.getRegions()

    this.preloader.region = false
  },

  methods: {
    ...mapActions(['getFilters']),

    finishFiltersLoading () {
      this.$set(this.preloader, 'filters', false)
    }
  }
}
</script>
