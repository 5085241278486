<template>
  <vue-line>
    <vue-item>
      <div class="query-name">
        <v-table-item-title
            class="_ml-0"
            :title="item.url_title"
            :is-listing="isListing"
            :listing-page="item.listingPage"
            :type="item.type_id"
            :entity-count="entityCount"
        />
      </div>
    </vue-item>
    <vue-item align="right">{{ level }}</vue-item>
    <v-table-data-cell
        v-for="(metric, i) in metrics"
        :key="`${i}-value`"
        :class="item[metric.id] && item[metric.id].category"
        :value="item[metric.id] && item[metric.id].value"
        :additional="metric.additional"
        :data-test="`table_cell_${metric.id}${isMainPage ? '--main' : ''}`"
    />
  </vue-line>
</template>

<script>
import tableItemTitle from '@/services/components/tableItemTitle.vue'
import tableDataCell from '@/services/components/tableDataCell.vue'

import {roundNumber} from '@/function'

export default {
  components: {
    'v-table-item-title': tableItemTitle,
    'v-table-data-cell': tableDataCell
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    metrics: {
      type: Array,
      default: () => ([])
    },

    level: {
      type: Number,
      default: undefined
    }
  },

  computed: {
    isListing () {
      try {
        return this.item.is_listing
      } catch (_) {
        return false
      }
    },

    isMainPage () {
      try {
        return this.item.url_title.toLowerCase().includes('главная')
      } catch (_) {
        return false
      }
    },

    entityCount () {
      return this.$store.state.entityCountTypes.map(type => {
        return {
          type: type.id,
          count: this.item[`count_${type.id}`] || 0,
          name: type.name
        }
      })
    }
  },

  methods: {
    roundNumber
  }
}
</script>

<style scoped lang="scss">
.a {
  background: #E2FFEE;
}

.b {
  background: #FFFCE2;
}

.c {
  background: #FFECE7;
}
</style>
